<template>
    <div class="main-container">
      <h5 class="pageTitle">발급기관 정보</h5>

       <table class="table-container">
        <button class="btn-register" @click="goToRegister">등록</button>
        <tbody>
          <tr @click="goToUpdate" class="clickable-row">
            <th>기관명</th>
            <td>{{ data?.organization ?? '' }}</td>
          </tr>
          <tr @click="goToUpdate" class="clickable-row">
            <th>주소</th>
            <td>{{ data?.address ?? '' }}</td>
          </tr>
          <tr @click="goToUpdate" class="clickable-row">
            <th>담당자</th>
            <td>{{ data?.officer ?? '' }}</td>
          </tr>
          <tr @click="goToUpdate" class="clickable-row">
            <th>연락처</th>
            <td>{{ data?.phone ?? '' }}</td>
          </tr>
          <!-- <tr>
            <th>서명파일</th>
            <td>{{ data?.signature ?? '-' }}</td>
          </tr> -->
          <tr @click="goToUpdate" class="clickable-row">
            <th>등록일</th>
            <td>{{ formatDate(data?.createdAt) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  //import axios from 'axios'
  import axios from "@/axios";
  import Swal from "sweetalert2"; 
  export default {
    name: 'CertOrganRead',
    data() {
      return {
        data: {}, // 초기값을 빈 객체로 세팅
      }
    },
    mounted() {
      this.fetchData()
    },
        methods: {
          goToUpdate() {
            this.$router.push({ 
              name: 'master-certOrganUpdateDelete', 
              query: {
                data: JSON.stringify(this.data)  // 객체는 문자열로 변환해서 넘김
              }
            });
          },
          goToRegister() {
         
            if(Object.keys(this.data).length > 0){
              Swal.fire("알림", "발급기관이 이미 등록되어 있습니다.", "info");
              return;
            }
            this.$router.push({ name: "master-certOrganCreate" });
          },
          async fetchData() {
              try {
                  //const token = sessionStorage.getItem('token')  // 또는 this.$store.state.token
                  const tokenData = JSON.parse(sessionStorage.getItem("token"));
                  const token = tokenData?.access_token || "";
                  const res = await axios.get('/master/issuing-organization', {
                      headers: {
                          Authorization: `Bearer ${token}`
                      }
                  });
                  this.data = Array.isArray(res.data) && res.data.length > 0 ? res.data[0] : {};
              } catch (err) {
                  console.error('발급기관 조회 실패:', err);
              }
          },
          formatDate(dateStr) {
              if (!dateStr) return ''
              const d = new Date(dateStr)
              return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
          }
    
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 60px;
  }
  
  .table-container{
    position: relative;
    width: 80%;      /*fit-content;  또는 100%로 조정 가능 */
    margin: 0 auto; /* 가운데 정렬 필요시 */
  }

  .btn-register {
    position: absolute;
    top: -50px; /* 타이틀 아래 위치 */
    right: 0;
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    color: #3e3e3e;
  }

  .clickable-row {
    cursor: pointer;
  }
  .clickable-row:hover {
    background-color: #f8f8f8;
  }
 
  
  table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  .clickable-row {
    cursor: pointer;
  }
 
 
  </style>